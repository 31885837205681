import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavBar from '../components/NavBar';
import Contact from '../components/Contact';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPalette,
  faChevronDown,
  faBicycle,
  faRunning,
  faGlobeAsia,
  faCat,
  faSeedling,
  faPodcast,
  faBeer,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import FrisbeeIcon from '../images/frisbee.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  image: {
    borderRadius: 10,
    margin: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  stripesLeft: {
    padding: 0,
    margin: 0,
    height: '25vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transformOrigin: 'left',
    transform: 'skewY(8deg)',
  },
  stripesRight: {
    padding: 0,
    margin: 0,
    height: '15vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transformOrigin: 'right',
    transform: 'skewY(-8deg)',
  },
  align: {
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Roboto Condensed',
    textTransform: 'uppercase',
    fontWeight: 500,
    textAlign: 'center',
  },
  border: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  overrideColor: {
    color: theme.palette.primary.main,
  },
  iconSize: {
    width: '2em',
    height: '2em',
    textAlign: 'center',
  },
  font: {
    fontFamily: 'Roboto Condensed',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
}));

export default function About() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      kitty: file(relativePath: { eq: "kitty.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Container maxWidth="xl">
        <Box my={5}>
          <NavBar />
        </Box>
        <Box className={classes.stripesLeft}></Box>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} lg={5}>
            <Box mx={3}>
              <Typography variant="h2" className={classes.font} gutterBottom>
                How I Got Here*
              </Typography>
              <Typography variant="h6" gutterBottom className={classes.root}>
                *here: adv. at, in, or to, this place
              </Typography>
            </Box>
            <Box mx={3}>
              <Typography variant="h5" gutterBottom>
                I recently completed a full stack software engineering bootcamp
                to build on my experiences in digital operations, marketing and
                customer service. I'm an artist at heart, so when I'm not
                geeking out over the perfect color palette for my next app, I'm
                likely painting portraits of puppies for friends. Which is
                highly ironic considering I'm a self-proclaimed, unapologetic
                cat lady.
              </Typography>
              <Typography variant="h5" gutterBottom>
                In my next role, I'd love to work amongst a team of inspiring
                developers and designers. I'm a sponge and would love an
                environment where I can continue to learn from those around me.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Img
              className={classes.image}
              alt="me and my coding buddy, Rafi"
              fluid={data.kitty.childImageSharp.fluid}
            />
          </Grid>
        </Grid>
        <Box className={classes.stripesRight}></Box>
        <Grid container spacing={4} justify="center">
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom className={classes.title}>
              My Favorite Tools
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.align}
            ></Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              JavaScript
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              Node.js
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              Express.js
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              PostgreSQL
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              React.js
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              GSAP
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              Git/Github
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              Chrome Dev Tools
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              VS Code
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              Photoshop
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              Illustrator
            </Typography>
            <Typography variant="h5" className={classes.align} gutterBottom>
              InDesign
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom className={classes.title}>
              Excited To Learn
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              React Native
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              Figma
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              AdobeXD
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              AWS
            </Typography>
            <Typography variant="h5" gutterBottom className={classes.align}>
              Kotlin
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.align}>
            <Typography variant="h3" gutterBottom className={classes.title}>
              To Relax I...
            </Typography>
            <Typography variant="h6" gutterBottom className={classes.align}>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={2}
                className={classes.align}
              >
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faPalette}
                        size="2x"
                      />
                    </Box>
                    <Box>Paint</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faBicycle}
                        size="2x"
                      />
                    </Box>
                    <Box>Bike</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faRunning}
                        size="2x"
                      />
                    </Box>
                    <Box>Run</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faSeedling}
                        size="2x"
                      />
                    </Box>
                    <Box>Garden</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box className={classes.align}>
                      <FrisbeeIcon className={classes.iconSize} />
                    </Box>
                    <Box>Play Frisbee</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faGlobeAsia}
                        size="2x"
                      />
                    </Box>
                    <Box>Travel</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faPodcast}
                        size="2x"
                      />
                    </Box>
                    <Box>Listen to Podcasts</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faCat}
                        size="2x"
                      />
                    </Box>
                    <Box>Pet Cats</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faBeer}
                        size="2x"
                      />
                    </Box>
                    <Box>Drink Good Beer</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <FontAwesomeIcon
                        className={classes.overrideColor}
                        icon={faBook}
                        size="2x"
                      />
                    </Box>
                    <Box>Read a Mystery</Box>
                  </Box>
                </Grid>
              </Grid>
              {/* <FrisbeeIcon /> */}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.border}>
            <Typography variant="h5" className={classes.align}>
              Want to swap stories?
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.align}>
            <FontAwesomeIcon
              icon={faChevronDown}
              size="3x"
              className={classes.overrideColor}
            />
          </Grid>
        </Grid>
      </Container>
      <Box mt={5}>
        <Contact />
      </Box>
    </>
  );
}
